import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";
import FormEmail from "../components/FormEmail";
import SpacingVertical from "../components/SpacingVertical";

const ContactPage = () => (
  <Layout override="marginStandard">
    <SEO title={Strings.Contact.title} />
    <div className="marginFocus">
      <SpacingVertical rems={4} />
      <h1>{Strings.Contact.message}</h1>
      <SpacingVertical rems={1} />
      <ul>
        <li>{Strings.Contact.Consulting.title}</li>
        <li>{Strings.Contact.VisionAPIs.title}</li>
        <li>{Strings.Contact.ModelHosting.title}</li>
      </ul>
      <SpacingVertical rems={1} />
      <FormEmail
        labelColor={Colors.blueDark}
        inputColor={Colors.grayLight}
        buttonTitle={Strings.FormEmail.button}
      />
      <SpacingVertical rems={4} />
    </div>
  </Layout>
);

export default ContactPage;
